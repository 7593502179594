<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>

            <form class="needs-validation" @submit.prevent="poForm">
              <div class="row">
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="branchId">สาขาที่สั่งซื้อ:</label>

                    <multiselect
                      id="branchId"
                      v-model="part.branchId"
                      :options="localDataBranchUser"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.part.branchId.$error,
                      }"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      :disabled="this.part.status == 'APV'"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.part.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.branchId.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="supId">ผู้แทนจำหน่าย:</label>

                    <multiselect
                      id="supId"
                      v-model="part.supId"
                      :options="rowSup"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.part.supId.$error,
                      }"
                      label="nameTh"
                      :show-labels="false"
                      track-by="nameTh"
                      :disabled="this.part.status == 'APV'"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.part.supId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.supId.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="poDate"> วันที่สั่งซื้อ:</label>
                    <!-- disabled="disabled"  -->
                    <date-picker
                      :disabled="this.part.status == 'APV'"
                      v-model="part.poDate"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                      :class="{
                        'is-invalid': submitform && $v.part.poDate.$error,
                      }"
                    ></date-picker>
                    <div
                      v-if="submitform && $v.part.poDate.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.poDate.required">{{ message }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code> <label for="poDcPer"> ส่วนลด(%):</label>
                    <!-- disabled="disabled"  -->
                    <input
                      id="poDcPer"
                      v-model="poDcPer"
                      type="number"
                      class="form-control text-end"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.poDcPer.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.poDcPer.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.poDcPer.required">{{ message }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="mb-3 position-relative">
                    <label>ประเภทภาษี:</label>

                    <multiselect
                      id="vatType"
                      v-model="part.vatType"
                      :options="optionVatType"
                      class=""
                      label="text"
                      :show-labels="false"
                      track-by="text"
                      @select="dis"
                    ></multiselect>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <code>* </code> <label for="vatPer"> ภาษี(%):</label>
                    <!-- disabled="disabled"  -->
                    <input
                      id="vatPer"
                      v-model="part.vatPer"
                      type="number"
                      class="form-control text-end"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.part.vatPer.$error,
                      }"
                    />

                    <div
                      v-if="submitform && $v.part.vatPer.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.part.vatPer.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md">
                    <button
                      class="btn btn-success float-end"
                      type="submit"
                      v-show="SuccessPost"
                    >
                      บันทึก
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>

    <!-- end table -->
  </Layout>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import { _ } from "vue-underscore";

/**
 * Form validation component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  page: {
    title: appConfig.po,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: false,
      enabled: false,
      modalShow: false,
      message: "โปรดระบุข้อมูล",
      title: "เพิ่มการสั่งซื้ออะไหล่",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: appConfig.po,
          href: "/purchase-order",
        },
        {
          text: "เพิ่มการสั่งซื้อ",
          active: true,
        },
      ],
      optionTypeDc: [
        {
          name: "บาท",
          bath: "bath",
        },
        {
          name: "เปอรเซ็น",
          per: "per",
        },
      ],

      optionVatType: [
        {
          text: "ภาษีนอก(ยังไม่รวมภาษี)",
          id: 1,
        },
        {
          text: "ภาษีใน(รวมภาษี)",
          id: 2,
        },
      ],
      isDisabled: false,
      rowRoParts: [],
      SuccessPost: true,
      partId: this.$route.params.partId,
      rowSup: [],
      partsCode: [],
      nameState: null,
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      perPage: 10,
      currentPage: 1,
      rowPoParts: [],
      rowParts: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      orderDate: "",
      branchOrder: null,
      orderType: null,
      orderCompany: null,
      orderPer: null,
      options: [],
      selectedPart: "",
      // รวมpo
      poTotalPrice: 0, // มูลค่ารวมอะไหล่
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: 0,
      poVatPer: appConfig.vat,
      poVatPrice: 0,
      poGrandTotalPrice: 0,
      part: {
        partId: "",
        partCode: "",
        nameTh: "",
        selectPart: {},
        pricePerOne: 0,
        amount: 0,
        totalPrice: 0,
        dcPer: 0,
        dcPrice: 0,
        netPrice: 0,
        dcMethod: "",
        vatPrice: 0,
        vatPer: appConfig.vat,
        grandTotalPrice: 0, // มูลค่ารวมคิดภาษี
        branchId: "",
        supId: "",
        poDate: "",
        vatType: { text: "ภาษีนอก(ยังไม่รวมภาษี)", id: 1 },
        id: "",
      },
      searchform: {
        partsCode: "",
      },
      submit: false,
      submitted: false,
      submitform: false,
      isHidden: false,
    };
  },
  validations: {
    poDcPer: { required },
    part: {
      amount: {
        required,
        numeric,
      },
      branchId: {
        required,
      },
      poDate: {
        required,
      },
      vatPer: {
        required,
      },
      supId: {
        required,
      },
    },
  },
  computed: {},

  mounted() {},

  created() {
    this.getLocalData();
    this.getSup();
    // this.getPoPart();
  },
  methods: {
    dis(option) {
      if (option.id === 2) {
        this.part.vatPer = 0;
        this.isDisabled = true;
      } else if (option.id === 1 || option == null) {
        this.part.vatPer = 7;
        this.isDisabled = false;
      }
    },
    calculatePo(part) {
      let totalPriceCal = parseFloat(part.pricePerOne * part.amount);
      if (!isNaN(totalPriceCal)) {
        part.totalPrice = totalPriceCal.toFixed(2);
      }
      var DcPricecal = 0;

      DcPricecal = parseFloat(part.totalPrice) * parseFloat(part.dcPer / 100);
      if (!isNaN(DcPricecal)) {
        part.dcPrice = DcPricecal.toFixed(2);
      }

      let calNetPrice = 0;

      if (this.part.vatType.id === 1) {
        calNetPrice = parseFloat(part.totalPrice - part.dcPrice);
        if (!isNaN(calNetPrice)) {
          part.netPrice = calNetPrice.toFixed(2);
        }
      } else if (this.part.vatType.id === 2) {
        calNetPrice = parseFloat(part.totalPrice * parseFloat(100 / 107));
        if (!isNaN(calNetPrice)) {
          part.netPrice = calNetPrice.toFixed(2);
        }
      } else {
        calNetPrice = parseFloat(part.totalPrice - part.dcPrice);
        if (!isNaN(calNetPrice)) {
          part.netPrice = calNetPrice.toFixed(2);
        }
      }

      let calVatPrice = 0;

      if (this.part.vatType.id === 1) {
        calVatPrice = part.netPrice * parseFloat(part.vatPer / 100);
        if (!isNaN(calVatPrice)) {
          part.vatPrice = calVatPrice.toFixed(2);
        }
      } else if (this.part.vatType.id === 2) {
        calVatPrice = parseFloat(part.totalPrice - part.netPrice);
        if (!isNaN(calVatPrice)) {
          part.vatPrice = calVatPrice.toFixed(2);
        }
      } else {
        calVatPrice = part.netPrice * parseFloat(part.vatPer / 100);
        if (!isNaN(calVatPrice)) {
          part.vatPrice = calVatPrice.toFixed(2);
        }
      }

      let calGrandTotalPrice = 0;
      if (this.part.vatType.id === 1) {
        calGrandTotalPrice =
          parseFloat(part.netPrice) + parseFloat(part.vatPrice);
        if (!isNaN(calGrandTotalPrice)) {
          part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
        }
      } else if (this.part.vatType.id === 2) {
        part.grandTotalPrice = parseFloat(part.totalPrice - part.dcPrice);
      } else {
        calGrandTotalPrice =
          parseFloat(part.netPrice) + parseFloat(part.vatPrice);
        if (!isNaN(calGrandTotalPrice)) {
          part.grandTotalPrice = calGrandTotalPrice.toFixed(2);
        }
      }
      this.calTotal();
    },
    calTotal() {
      let poTotalPrice, poDcPrice, poNetPrice, poVatPrice, poGrandTotalPrice;

      poTotalPrice = this.rowPoParts.reduce(function (sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);
      this.poTotalPrice = poTotalPrice.toFixed(2);
      poDcPrice =
        parseFloat(this.poTotalPrice) * parseFloat(this.poDcPer / 100);
      if (!isNaN(poDcPrice)) {
        this.poDcPrice = poDcPrice.toFixed(2);
      }
      poNetPrice = parseFloat(this.poTotalPrice) - parseFloat(this.poDcPrice);
      if (!isNaN(poNetPrice)) {
        this.poNetPrice = poNetPrice.toFixed(2);
      }

      poVatPrice = this.poNetPrice * parseFloat(this.poVatPer / 100);
      if (!isNaN(poVatPrice)) {
        this.poVatPrice = poVatPrice.toFixed(2);
      }

      poGrandTotalPrice =
        parseFloat(this.poNetPrice) + parseFloat(this.poVatPrice);
      if (!isNaN(poGrandTotalPrice)) {
        this.poGrandTotalPrice = poGrandTotalPrice.toFixed(2);
      }
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;

      // console.log(this.branchId);
    },
    showModal(option) {
      this.selectedPart = option;
      this.part.partCode = option.partCode;
      this.part.pricePerOne = option.sellPrice;
      this.part.totalPrice = option.sellPrice;
      this.part.netPrice = option.sellPrice;
      this.part.nameTh = option.nameTh;
      this.part.partId = option.partId;
      this.$refs.modalAmout.show(option);
    },

    customLabel({ partCode, nameTh }) {
      return `[${partCode}] - ${nameTh != null ? nameTh : ""}`;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowTest = filteredItems.length;
      this.currentPage = 1;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // eslint-disable-next-line no-unused-vars
    modalForm() {
      this.$v.$touch();
      this.submitted = true;
    },
    searchForm() {
      this.$v.$touch();
      this.submit = true;
    },

    poForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.part.$invalid != true) {
        this.postPo();
      }
    },
    postPo: function () {
      this.overlayFlag = true;

      // // parse

      useNetw
        .post("api/part-po/store", {
          branchId: this.part.branchId.branchId,
          supId: this.part.supId.supId != null ? this.part.supId.supId : "",
          poDate: this.part.poDate,
          vatPer: this.part.vatPer,
          dcPer: this.poDcPer,
        })
        .then((response) => {
          this.poId = response.data.poId;
          this.isHidden = true;
          this.SuccessPost = false;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.getPoPart(this.poId);
          this.$router.push({
            name: "edit-po",
            params: {
              poId: this.poId,
            },
          });
          // console.log("postRoWage", this.roId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.getPoShow(this.poId);
          this.overlayFlag = false;
        });
    },
    postPoPart: function (part) {
      let arr = [];
      this.rowPoParts.forEach((item) => {
        arr.push(item.partId);
      });
      if (!arr.includes(part.partId)) {
        this.rowPoParts.push(part);
      }
      this.overlayFlag = true;
      part.partId = this.selectedPart.partId;
      part.nameTh = this.selectedPart.nameTh;
      part.pricePerOne = this.selectedPart.sellPrice;
      part.totalPrice = this.selectedPart.sellPrice;
      part.netPrice;
      this.calculatePo(part);
      this.calTotal();
      // console.log("postPoPart", this.poTotalPrice);
      const formDataPart = new FormData();
      formDataPart.append("poId", this.poId);
      formDataPart.append("partId", part.partId);
      formDataPart.append("amount", part.amount);
      formDataPart.append("pricePerOne", part.pricePerOne);
      formDataPart.append("totalPrice", part.totalPrice);
      formDataPart.append("dcPrice", part.dcPrice);
      formDataPart.append("dcPer", part.dcPer);
      formDataPart.append("netPrice", part.netPrice);
      formDataPart.append("vatPer", part.vatPer);
      formDataPart.append("vatPrice", part.vatPrice);
      formDataPart.append("grandTotalPrice", part.grandTotalPrice);
      formDataPart.append("poTotalPrice", this.poTotalPrice);
      formDataPart.append("poDcPer", this.poDcPer);
      formDataPart.append("poDcPrice", this.poDcPrice);
      formDataPart.append("poNetPrice", this.poNetPrice);
      formDataPart.append("poVatPer", this.poVatPer);
      formDataPart.append("poVatPrice", this.poVatPrice);
      formDataPart.append("poGrandTotalPrice", this.poGrandTotalPrice);

      useNetw
        .post("api/part-po/part/store", formDataPart)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.searchform.partsCode = "";
          this.$refs["modalAmout"].hide();
          this.getPoPart(this.poId);
          // console.log("postRoWage", this.roId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.$router.push({ name: "branch-wages" });
        });
    },
    updatePart(
      partId,
      sellPrice,
      amount,
      totalPrice,
      dcPer,
      dcPrice,
      netPrice,
      vatPer,
      vatPrice,
      grandTotalPrice
    ) {
      this.part.partId = partId;
      this.part.pricePerOne = sellPrice;
      this.part.amount = amount;
      this.part.totalPrice = totalPrice;
      this.part.dcPer = dcPer;
      this.part.dcPrice = dcPrice;
      this.part.netPrice = netPrice;
      this.part.vatPer = vatPer;
      this.part.vatPrice = vatPrice;
      this.part.grandTotalPrice = grandTotalPrice;

      useNetw
        .put("api/part-po/part/update", {
          poId: this.poId,
          partId: this.part.partId,
          amount: this.part.amount,
          pricePerOne: this.part.pricePerOne,
          totalPrice: this.part.totalPrice,
          dcPer: this.part.dcPer,
          dcPrice: this.part.dcPrice,
          netPrice: this.part.netPrice,
          vatPer: this.part.vatPer,
          vatPrice: this.part.vatPrice,
          grandTotalPrice: this.part.grandTotalPrice,
          poTotalPrice: this.poTotalPrice,
          poDcPer: this.poDcPer,
          poDcPrice: this.poDcPrice,
          poNetPrice: this.poNetPrice,
          poVatPer: this.poVatPer,
          poVatPrice: this.poVatPrice,
          poGrandTotalPrice: this.poGrandTotalPrice,
        })

        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.getPoPart(this.poId);
          this.calTotal();
          // this.overlayFlag = false;
        });
    },
    updatePo() {
      useNetw
        .put("api/part-po/update", {
          poId: this.poId,
          supId: this.part.supId.supId,
          poDate: this.part.poDate,
          totalPrice: this.poTotalPrice,
          dcPer: this.poDcPer,
          dcPrice: this.poDcPrice,
          netPrice: this.poNetPrice,
          vatPer: this.poVatPer,
          vatPrice: this.poVatPrice,
          grandTotalPrice: this.poGrandTotalPrice,
        })

        .then((response) => {
          this.calTotal();
          this.getPoPart(this.poId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.$router.push({ name: "purchase-order" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    putSubmit() {
      useNetw
        .put("api/part-po/submit", {
          poId: this.poId,
        })

        .then((response) => {
          // console.log("putsubmit",response);
          this.calTotal();
          this.getPoPart(this.poId);

          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          // this.alertSubmit(response);
          this.$router.push({ name: "purchase-order" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getPoShow: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/part-po/show", {
          params: {
            poId: this.poId,
          },
        })
        .then((response) => {
          this.rowRoShow = response.data.data;
          this.part.status = response.data.data.status;
          this.part.branchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.part.supId = {
            supId: response.data.data.supId,
            nameTh: response.data.data.supplierNameTh,
          };
          this.part.poDate = response.data.data.poDate;
          this.poDcPer = response.data.data.dcPer;
          this.poDcPrice = response.data.data.dcPrice;
          this.poTotalPrice = response.data.data.totalPrice;
          this.poNetPrice = response.data.data.netPrice;
          this.poVatPer = response.data.data.vatPer;
          this.poVatPrice = response.data.data.vatPrice;
          this.poGrandTotalPrice = response.data.data.grandTotalPrice;
          this.calculatePo();
          this.calTotal();

          // console.log("show", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.loading = false; //skeleton false
        });
    },
    getParts: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-po/branch-part", {
          params: {
            page: this.currentPage,
            perPage: 100, //เฉพาะค้นหา สูงสุด 100
            branchId: this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowParts = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getSup: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.branchId,
          },
        })
        .then((response) => {
          this.rowSup = response.data.data;
          // console.log(response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getPoPart: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-po/parts", {
          params: {
            poId: this.poId,
          },
        })
        .then((response) => {
          this.rowPoParts = response.data.data;
          this.calTotal();
          // console.log("getPo", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;

          this.loading = false; //skeleton false
        });
    },

    deletePoPart: function (rowPoItem, index) {
      this.rowPoParts.splice(index, 1);
      this.calTotal(this.rowPoParts);

      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-po/part/delete", {
          data: {
            partId: rowPoItem.partId,
            poId: this.poId,
            poTotalPrice: this.poTotalPrice,
            poDcPer: this.poDcPer,
            poDcPrice: this.poDcPrice,
            poNetPrice: this.poNetPrice,
            poVatPer: this.poVatPer,
            poVatPrice: this.poVatPrice,
            poGrandTotalPrice: this.poGrandTotalPrice,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getPoPart();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowPoItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ !!?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletePoPart(rowPoItem, index);
          }
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันการสั่งซื้อหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.putSubmit();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.con-nav-footer {
  padding-left: 20rem;
}
.part {
  width: 240px;
}
.wage {
  width: 200px;
}
.number {
  width: 100px;
  text-align: right;
}
.Dc {
  width: 80px;
}
.action {
  width: 40px;
}
.numberP {
  width: 140px;
  text-align: right;
}
.price {
  width: 170px;
  text-align: right;
}
</style>

