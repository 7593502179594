var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"card-title-desc"}),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.poForm.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"branchId"}},[_vm._v("สาขาที่สั่งซื้อ:")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.part.branchId.$error,
                    },attrs:{"id":"branchId","options":_vm.localDataBranchUser,"label":"nameTh","show-labels":false,"track-by":"nameTh","disabled":this.part.status == 'APV'},model:{value:(_vm.part.branchId),callback:function ($$v) {_vm.$set(_vm.part, "branchId", $$v)},expression:"part.branchId"}}),(_vm.submitform && _vm.$v.part.branchId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.part.branchId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"supId"}},[_vm._v("ผู้แทนจำหน่าย:")]),_c('multiselect',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.part.supId.$error,
                    },attrs:{"id":"supId","options":_vm.rowSup,"label":"nameTh","show-labels":false,"track-by":"nameTh","disabled":this.part.status == 'APV'},model:{value:(_vm.part.supId),callback:function ($$v) {_vm.$set(_vm.part, "supId", $$v)},expression:"part.supId"}}),(_vm.submitform && _vm.$v.part.supId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.part.supId.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-2 col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_c('label',{attrs:{"for":"poDate"}},[_vm._v(" วันที่สั่งซื้อ:")]),_c('date-picker',{class:{
                      'is-invalid': _vm.submitform && _vm.$v.part.poDate.$error,
                    },attrs:{"disabled":this.part.status == 'APV',"first-day-of-week":1,"format":"YYYY-MM-DD","value-type":"format","lang":"en"},model:{value:(_vm.part.poDate),callback:function ($$v) {_vm.$set(_vm.part, "poDate", $$v)},expression:"part.poDate"}}),(_vm.submitform && _vm.$v.part.poDate.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.part.poDate.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-6 col-md-2 col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_vm._v(" "),_c('label',{attrs:{"for":"poDcPer"}},[_vm._v(" ส่วนลด(%):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.poDcPer),expression:"poDcPer"}],staticClass:"form-control text-end",class:{
                      'is-invalid': _vm.submitform && _vm.$v.poDcPer.$error,
                    },attrs:{"id":"poDcPer","type":"number","value":"Otto"},domProps:{"value":(_vm.poDcPer)},on:{"input":function($event){if($event.target.composing)return;_vm.poDcPer=$event.target.value}}}),(_vm.submitform && _vm.$v.poDcPer.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.poDcPer.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-sm-6 col-md-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',[_vm._v("ประเภทภาษี:")]),_c('multiselect',{attrs:{"id":"vatType","options":_vm.optionVatType,"label":"text","show-labels":false,"track-by":"text"},on:{"select":_vm.dis},model:{value:(_vm.part.vatType),callback:function ($$v) {_vm.$set(_vm.part, "vatType", $$v)},expression:"part.vatType"}})],1)]),_c('div',{staticClass:"col-6 col-md-2 col-sm-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v("* ")]),_vm._v(" "),_c('label',{attrs:{"for":"vatPer"}},[_vm._v(" ภาษี(%):")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.part.vatPer),expression:"part.vatPer"}],staticClass:"form-control text-end",class:{
                      'is-invalid': _vm.submitform && _vm.$v.part.vatPer.$error,
                    },attrs:{"id":"vatPer","type":"number","value":"Otto"},domProps:{"value":(_vm.part.vatPer)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.part, "vatPer", $event.target.value)}}}),(_vm.submitform && _vm.$v.part.vatPer.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.part.vatPer.required)?_c('span',[_vm._v(_vm._s(_vm.message))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.SuccessPost),expression:"SuccessPost"}],staticClass:"btn btn-success float-end",attrs:{"type":"submit"}},[_vm._v(" บันทึก ")])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }